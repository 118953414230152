import { createContext, useEffect, useState } from 'react'
import reactLogo from './assets/react.svg'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Projects from './pages/Projects'
import AddProject from './pages/AddProject'
import Navbar from './components/Navbar' 
import { getAllProjects } from './api/api'
import ProjectPage from './pages/ProjectPage'
import axios from 'axios'
import ProjectAll from './components/ProjectAll'
import ProjectResidence from './components/ProjectResidence'
import ProjectInstitute from './components/ProjectInstitute'
import ProjectHousing from './components/ProjectHousing'
import ProjectCommercial from './components/ProjectCommercial'
import Staff from './pages/Staff'
import Login from './pages/Login'
import Register from './components/Register'
import Loading2 from './components/Loading2'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const ProjectContext = createContext()
export const MutateContext = createContext()

 

function App() {
  const [projects,setProjects] = useState({isLoading:true,data:[]})
  const [mutate, setMutate] = useState(1)
  const [signIn,setSignIn] = useState(false)



  useEffect(()=>{
    getAllProjects().then((res)=>{ 
      setProjects({isLoading:false,
      data:res.data.projects.Items}) 
    })
    // getAllProjects().then((res)=>console.log(res))
 
  },[mutate]) 
  

  return (
    <div className=" ">
      <ToastContainer />
      <ProjectContext.Provider value={projects}>
      <MutateContext.Provider value={{mutate,setMutate  }}>
      <BrowserRouter>
      {/* <Navbar   /> */}
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/register' element={<Register/>}/>
          <Route path='/loading' element={<Loading2/>}/>

          <Route path='/projects' element={<Projects/>}>
            <Route index element={<ProjectAll/>}/>
            <Route path='residence' element={<ProjectResidence/>}/>
            <Route path='institute' element={<ProjectInstitute/>}/>
            <Route path='housing' element={<ProjectHousing/>}/>
            <Route path='commercial' element={<ProjectCommercial/>}/>
          </Route> 

          <Route path='/addproject' element={<AddProject/>}/>
          <Route path='/:id' element={<ProjectPage/>}/> 
        </Routes>
      </BrowserRouter>
      </MutateContext.Provider>
      </ProjectContext.Provider>
    </div>
  )
}

export default App
