import React, { useContext } from 'react'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getProjectType } from '../api/api'; 
import Loading2 from './Loading2'

const ProjectCommercial = () => {

  // const { data, isLoading } = useContext(ProjectContext);
  let {data,isLoading,isError,isFetching,isFetched,refetch} = useQuery('projectCommercial',()=>getProjectType('commercial'))

  console.log(data?.projects?.Items)
  return (
       <div className="w-full h-fit justify-center flex flex-wrap gap-2  py-2">
        {isLoading && <Loading2/>}
         
        {isFetched &&
          data?.projects?.Items?.map((x, i) => (
            <Link
              className="w-fit h-fit relative cursor-pointer"
              key={i}
              to={`/${x.id}`}
            >
              <div className="w-[300px] h-[200px]">
                <img
                  src={`https://s3-mern-demo.s3.ap-south-1.amazonaws.com/${x.coverImg?.name}`}
                  className="w-full h-full object-cover"
                  alt="" 
                />
              </div>
              <div className="py-2 flex   gap-2">
                <img
                  src={`https://s3-mern-demo.s3.ap-south-1.amazonaws.com/${x.projectlogo?.name}`}
                  className="w-10 h-10    object-cover "
                  alt=""
                />
                <div className="text-left">
                  <p className="text-sm font-semibold">BHAVYARAJ</p>
                  <p className="text-sm text-slate-800">Surat, Gujrat</p>
                </div>
              </div>
            </Link>
          ))}
          
      </div>  
  )
}

export default ProjectCommercial