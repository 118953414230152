import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
 
 

const Home = () => {
  
  const navigate = useNavigate()
  var id =  Math.random().toString(16).slice(2)
  console.log(id)
  
  useEffect(()=>{
    if(sessionStorage.getItem('Auth Token')){
      navigate('/projects')
    }else{
      navigate('/login')
    }
  },[])
   
  return (
    <>
    <Navbar/>
    <div>
        {/* <img src={ 'https://s3-mern-demo.s3.ap-south-1.amazonaws.com/1030.jpg' || 'https://s3-mern-demo.s3.ap-south-1.amazonaws.com/10308.jpg'} alt="" /> */}
    </div>
    </>
  )
}

export default Home