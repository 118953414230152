import React, { memo, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MutateContext } from "../App";
import logo from "../assets/Logo.png";

const Navbar = () => {  
  const [signIn,setSignIn] = useState(false) 
  const navigate = useNavigate()

  const handleSignOut = () => { 
    sessionStorage.removeItem('Auth Token')
    setSignIn(false)
    navigate('/')
  }

  useEffect(()=>{
    if(sessionStorage.getItem('Auth Token')){
      setSignIn(true)
    }else{
      setSignIn(false)
    }
  },[signIn,setSignIn])
  

  return (
    <div className="bg-slate-800 px-4 flex items-center justify-between text-white w-full font-lato drop-shadow-lg fixed h-20 z-20">
      <div className="flex items-center gap-4">
        <Link to={'/'}>
          <img src={logo} alt="" className="w-12" />
        </Link>
        <span className="text-xl gap-2 flex">
          <span className="font-semibold">PRAYOSHA</span>
          <span>ARCHITECTS</span>
        </span>
      </div>

      <div className="gap-4 [&>a]:px-2 text-sm font-base uppercase"> 
        {signIn && <button onClick={handleSignOut} className='uppercase hover:underline'>SignOut</button>}
      </div>
    </div>
  );
};

export default  memo(Navbar);
