import { deleteFile, uploadFile } from "./S3"

export const randomName = () =>{
    const id = Math.random().toString(16).slice(2)
    return id
}

export function deleteBufferFromImage(project){
    let project1 = structuredClone(project)
    delete project1.coverImg.body
    delete project1.projectlogo.body
    for (let i = 0; i < project1.slides.length; i++) {
      delete project1.slides[i].body
    }
    for (let i = 0; i < project1.sitephotos.length; i++) {
      delete project1.sitephotos[i].body
    }
    return project1
}

export async function saveFilesToS3(arr){
    const response =[]
    for (let i = 0; i < arr.length; i++) {
        const result = await uploadFile(arr[i].body, arr[i].name)
        response.push(result)
    }
    return response
}

export async function deleteFilesFromS3(arr){
  const response = []
  for(let i = 0; i < arr.length; i++){
    const result = await deleteFile(arr[i])
    response.push(result)
  }
  return response
}

