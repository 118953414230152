import React, { useContext, useEffect } from "react";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { ProjectContext } from "../App";
import Navbar from "../components/Navbar";

const Projects = () => {
  const { data, isLoading } = useContext(ProjectContext);
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation() 
  const pathname = location.pathname.split('/').pop()

  useEffect(()=>{ 
    if(!sessionStorage.getItem('Auth Token')){
      navigate('/')
    }
  },[])

  return (
    <>
    <Navbar/>
    <div className="  w-full min-h-screen px-10 pt-20 bg-white">
      <div className="  flex items-center py-4 px-2 justify-between">
        <Link
          to={"/addproject"}
          className=" text-white bg-blue-400 hover:bg-blue-500 rounded-md px-2 py-1 flex  items-center gap-1"
        >
          <AiOutlinePlus size={20} /> ADD PROJECT
        </Link>
        <span className="border border-slate-500 rounded-md flex items-center px-2 bg-white active:bg-slate-300">
          <AiOutlineSearch />
          <input
            type="text"
            className="px-2 py-1 outline-none rounded-md bg-transparent"
          />
        </span>
      </div>
      <div className="border-b border-slate-400 flex items-center py-2 px-2 justify-between">
        <span
          to={"/addproject"}
          className=" text-black   rounded-md px-2 py-1 flex  items-center gap-1"
        >
          Sort :{" "}
        </span>
        <span className="rounded-md flex items-center px-2   [&>a]:px-2   [&>a]:cursor-pointer [&>a]:font-base [&>a]:uppercase [&>a]:text-sm">
          <Link to={'/projects'} className={`active:text-black ${pathname==='projects' ? 'text-black':'text-slate-400'} active:underline hover:text-slate-800 hover:underline`}>All</Link>
          <Link to={'/projects/housing'} className={`active:text-black ${pathname==='housing' ? 'text-black':'text-slate-400'} active:underline hover:text-slate-800 hover:underline`}>Housing</Link>
          <Link to={'/projects/residence'} className={`active:text-black ${pathname==='residence' ? 'text-black':'text-slate-400'} active:underline hover:text-slate-800 hover:underline`}>Residence</Link>
          <Link to={'/projects/commercial'} className={`active:text-black ${pathname==='commercial' ? 'text-black':'text-slate-400'} active:underline hover:text-slate-800 hover:underline`}>Commercial</Link>
          <Link to={'/projects/institute'} className={`active:text-black ${pathname==='institute' ? 'text-black':'text-slate-400'} active:underline hover:text-slate-800 hover:underline`}>Institute</Link>
        </span>
      </div>
      <Outlet/>
    </div>
    </>
  );
};

export default Projects;
