import React, { memo, useContext, useState } from "react";
import { BsImage, BsUpload } from "react-icons/bs";
import { BiRightArrow } from "react-icons/bi";
import { deleteBufferFromImage, saveFilesToS3 } from "../helper/helper";
import { saveProject } from "../api/api";
import { MutateContext, ProjectContext } from "../App";
import Navbar from "../components/Navbar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddProject = () => {
  const { mutate, setMutate } = useContext(MutateContext);
  const navigate = useNavigate()
  const [s3Upload, setS3Upload] = useState([]);
  const [s3CoverImg, setS3CoverImg] = useState();
  const [s3ProjectLogo, setS3ProjectLogo] = useState();

  const [openSlides, setOpenSlides] = useState(false);
  const [openSitePhotos, setSitePhotos] = useState(false);

  const [project, setProject] = useState({
    projectlogo: null,
    coverImg: null,
    name: null,
    location: null,
    area: null,
    doc: null,
    type: null,
    description: null,
    slides: [],
    sitephotos: [],
  });

  const handleChange = (e) => {
    setProject({ ...project, [e.target.name]: e.target.value });
  };

  const handleImage = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const id = Math.floor(Math.random() * 100000 + 1);
    const fileName = `${id}.${file.name.split(".")[1]}`;

    let fileFormat = "image/jpeg";
    if (file.name.split(".")[1] === "png") {
      fileFormat = "image/png";
    } else if (file.name.split(".")[1] === "jpeg") {
      fileFormat = "image/jpeg";
    }

    const img = new Image();

    // load the image from the input file
    const reader = new FileReader();
    reader.onload = function (e) {
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);

    // wait for the image to load
    img.onload = function () {
      // create a canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // set the canvas dimensions to match the image dimensions
      canvas.width = img.width;
      canvas.height = img.height;

      // draw the image onto the canvas
      ctx.drawImage(img, 0, 0);

      // compress the image and save it as a JPEG
      canvas.toBlob(
        function (blob) {
          // create a new file object from the compressed data
          const compressedFile = new File([blob], fileName, {
            type: fileFormat,
          });

          // setS3Upload([...s3Upload, { name: fileName, body: compressedFile }]);
          if(name==='projectlogo'){
            setS3ProjectLogo({ name: fileName, body: compressedFile })
          }else{
            setS3CoverImg({ name: fileName, body: compressedFile })
          } 
          setProject((prev) => {
            return {
              ...prev,
              [name]: { name: fileName, body: compressedFile },
            };
          });
        },
        fileFormat,
        0.5
      );
    };

    // setS3Upload([...s3Upload,{name:fileName, body:file}])
    // setProject((prev) => {
    //   return { ...prev, [name]: { name:fileName,body:file } };
    // });
  };

  const handleGallery = async (e) => {
    const files = e.target.files;
    const arr = [];

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const id = Math.floor(Math.random() * 100000 + 1);
      const fileName = `${id}.${file.name.split(".")[1]}`;
      let size = 1
      let fileFormat = "image/jpeg";
      if (file.name.split(".")[1] === "png") {
        fileFormat = "image/png";
        size = 4
      } else if (file.name.split(".")[1] === "jpeg") {
        fileFormat = "image/jpeg";
        size = 1
      }
      const img = new Image();

      // load the image from the input file
      const reader = new FileReader();
      reader.onload = function (e) {
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);

      img.onload = async function () {
        // create a canvas element
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // set the canvas dimensions to match the image dimensions
        canvas.width = img.width/size;
        canvas.height = img.height/size;

        // draw the image onto the canvas
        await ctx.drawImage(img, 0, 0,canvas.width, canvas.height);

        // compress the image and save it as a JPEG
        await canvas.toBlob(
          async function (blob) {
            // create a new file object from the compressed data
            const compressedFile =await new File([blob], fileName, {
              type: fileFormat,
            });

            arr.push({ name: fileName, body: compressedFile });
            setS3Upload([
              ...s3Upload,
              ...arr,
            ]);
            setProject({
              ...project,
              [e.target.name]: [...arr, ...project[e.target.name]],
            });
          },
          fileFormat,
          0.5
        );
      };
    }
  };

  const notifySuccess = () => toast.success('🦄 Wow so easy!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });


  console.log(s3Upload,project)
  const handleSubmit = async () => {
    let project1 = deleteBufferFromImage(project);
    project1.id = Math.floor(Math.random() * 100000 + 1);
    console.log(project);
    const response = await saveFilesToS3([...s3Upload,s3CoverImg,s3ProjectLogo]);
    console.log(response);
    if (!response.some((x) => x.$metadata.httpStatusCode !== 200)) {
      const request = await saveProject(project1).then(() =>{ setMutate(2);notifySuccess();navigate('/projects') });

      console.log(request);
    }
  };

  return (
    <>
    <Navbar/>
    <div className="flex flex-wrap bg-slate-100 w-full h-11/12 pt-20 pb-24 px-10 gap-4 ">
      <div
        className="w-full bg-white rounded-lg flex lg:flex-nowrap flex-wrap
            [&>div>h1]:border-b
            [&>div>h1]:border-slate-500
            [&>div>h1]:p-2 
            [&>div]:rounded-md
            [&>div]:bg-white
            mt-2 
            [&_input]:border-slate-400 
            [&_input]:border-2 
            [&_input]:rounded-md   
            [&_input]:px-2 
            [&_input]:bg-white 
            [&_input]:py-1 
            [&_input]:outline-none 
            [&_input]:my-1 
        "
      >
        <div className="lg:w-1/4 w-full  m-2 px-2">
          <h1>Project Details</h1>
          <div className="relative w-36 h-36 mx-auto my-2">
            <div className="w-36 h-36 bg-slate-100 border-dashed border-2 border-slate-400 z-10 absolute pointer-events-none flex flex-col items-center justify-center text-center overflow-hidden">
              {!project.projectlogo ? (
                <>
                  <BsImage size={35} color={"#4b5563"} />
                  <p className="text-slate-600 text-bold">
                    Upload Project Logo
                  </p>
                </>
              ) : (
                <>
                  <img
                    src={URL.createObjectURL(project.projectlogo.body)}
                    alt=""
                  />
                </>
              )}
            </div>
            <input
              type="file"
              name="projectlogo"
              id="projectlogo"
              onChange={handleImage}
              className="w-36 h-32 absolute inset-0 z-0 "
            />
          </div>

          <input
            type="text"
            name="name"
            placeholder="Project Name"
            onChange={handleChange}
            className="focus:border-2 focus:border-blue-500 w-full"
          />
          <input
            type="text"
            name="location"
            placeholder="Project Location"
            onChange={handleChange}
            className="focus:border-2 focus:border-blue-500 w-full"
          />
          <input
            type="number"
            name="area"
            placeholder="Sqft Area"
            onChange={handleChange}
            className="focus:border-2 focus:border-blue-500 w-full"
          />
          <input
            type="date"
            name="date"
            placeholder="date of Completion"
            onChange={handleChange}
            className="focus:border-2 focus:border-blue-500 w-full"
          />
          <select
            name="type"
            id=""
            className="px-2 py-1 outline-none border-2 border-slate-400 my-1 rounded-md w-full focus:border-blue-500"
            onChange={handleChange}
          >
            <option value="" className="bg-slate-200">
              Select Project type
            </option>
            <option value="residence" className="bg-slate-200">
              Residence
            </option>
            <option value="housing" className="bg-slate-200">
              housing
            </option>
            <option value="institute" className="bg-slate-200">
              institute
            </option>
            <option value="commercial" className="bg-slate-200">
              commercial
            </option>
          </select>
          <textarea
            className="outline-none focus:border-blue-400 border-slate-400 border-2 w-full rounded-md p-2 mt-1"
            type="text"
            name="description"
            placeholder="Project Description"
            onChange={handleChange}
          />
        </div>
        <div className=" w-full m-2  px-2">
          <h1>Cover Photo</h1>
          <div className="relative w-11/12 h-[450px] mx-auto my-2">
            <div className="w-full h-[450px] bg-slate-100 border-dashed border-2 border-slate-400 z-10 absolute pointer-events-none flex flex-col items-center justify-center text-center overflow-hidden ">
              {!project.coverImg ? (
                <>
                  <BsImage size={35} color={"#4b5563"} />
                  <p className="text-slate-600 text-bold">
                    Upload Project Logo
                  </p>
                </>
              ) : (
                <>
                  <img
                    src={URL.createObjectURL(project.coverImg.body)}
                    alt=""
                  />
                </>
              )}
            </div>
            <input
              type="file"
              name="coverImg"
              id="coverImg"
              onChange={handleImage}
              className="w-full h-[400px] absolute inset-0 z-0  "
            />
          </div>
        </div>
      </div>
      <div className="rounded-lg w-full h-fit bg-white px-4">
        <h1 className="  p-2  flex  items-center gap-2">
          Slides{" "}
          <button
            onClick={() => setOpenSlides(!openSlides)}
            className={openSlides ? "rotate-90" : "rotate-0"}
          >
            <BiRightArrow />
          </button>
        </h1>
        <div
          className={`w-full pb-4 flex  flex-wrap gap-2 ${
            openSlides ? "" : "hidden"
          }`}
        >
          {project.slides?.map((x, i) => (
            <div
              className="w-48 h-24 bg-slate-200 flex items-center justify-center relative"
              key={i}
            >
              <img
                src={URL.createObjectURL(x.body)}
                alt=""
                className="w-full h-full object-scale-down	"
              />
            </div>
          ))}
          <div className="w-48 h-24 bg-slate-200 rounded-md flex items-center justify-center relative">
            <BsUpload size={35} color={"#4b5563"} />
            <input
              type="file"
              className="w-full border border-slate-400 h-full absolute opacity-0"
              onChange={handleGallery}
              multiple
              name="slides"
            />
          </div>
        </div>
      </div>
      <div className="rounded-lg w-full h-fit bg-white px-4">
        <h1 className="  p-2   flex items-center gap-2">
          Site Photos{" "}
          <button
            onClick={() => setSitePhotos(!openSitePhotos)}
            className={openSitePhotos ? "rotate-90" : "rotate-0"}
          >
            <BiRightArrow />
          </button>
        </h1>
        <div
          className={`w-full pb-4 flex flex-wrap gap-2 ${
            openSitePhotos ? "" : "hidden"
          }`}
        >
          {project.sitephotos?.map((x, i) => (
            <div
              className="w-48 h-24 bg-slate-200 flex items-center justify-center relative"
              key={i}
            >
              <img
                src={URL.createObjectURL(x.body)}
                alt=""
                className="w-full h-full object-scale-down	"
              />
            </div>
          ))}
          <div className="w-48 h-24 bg-slate-200 rounded-md flex items-center justify-center relative">
            <BsUpload size={35} color={"#4b5563"} />
            <input
              type="file"
              className="w-full border border-slate-400 h-full absolute opacity-0"
              onChange={handleGallery}
              multiple
              name="sitephotos"
            />
          </div>
        </div>
      </div>
      <button
        className="px-2 py-2 border border-slate-600 text-slate-600 rounded-md mx-auto hover:bg-slate-600 hover:text-white font-semibold"
        onClick={handleSubmit}
      >
        ADD PROJECT
      </button>
    </div>
    </>

  );
};

export default memo(AddProject);
