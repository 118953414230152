import {
  GetObjectCommand,
  DeleteObjectCommand,
  S3Client,
  PutObjectCommand,
} from "@aws-sdk/client-s3"  
const bucketName = "s3-mern-demo";
const region = "ap-south-1";
const accessKeyId = "AKIAXDOEN3PPLE7ZXOHQ";
const secretAccessKey = "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";
 
export async function uploadFile(fileBuffer, fileName) {
  let contentType = 'image/jpeg'
  if(fileName.split('.')[1] === 'png'){
    contentType = 'image/png'
  }

  const uploadParams = {
    Bucket: bucketName,
    Body: fileBuffer,
    Key: fileName,
    ContentEncoding: 'base64',
    ContentType: contentType, 
  }

  return s3Client.send(new PutObjectCommand(uploadParams));
}

export const s3Client = new S3Client({
  region,
  credentials: {
    accessKeyId,
    secretAccessKey,
  },
});

export async function deleteFile(fileName) {
  const deleteParams = {
    Bucket: bucketName,
    Key: fileName,
  };

  const response =  await s3Client.send(new DeleteObjectCommand(deleteParams));
  console.log(response)
  return response
}

export async function getObjectSignedUrl(key) {
  const params = {
    Bucket: bucketName,
    Key: key,
  };

  // https://aws.amazon.com/blogs/developer/generate-presigned-url-modular-aws-sdk-javascript/
  const command = new GetObjectCommand(params);
  const seconds = 60;
  const url = await getObjectSignedUrl(s3Client, command, { expiresIn: seconds });
  console.log(url)
  return url;
}
