import React, { useState } from 'react'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { app } from '../firebase-config';

const Register = () => {

    const [cred, setCred] = useState({email:'',password:''})

    

    const handleChange = (e) => {
        setCred({
            ...cred,
            [e.target.name]:e.target.value
        })
    }

    const handleClick = () => {
        const authentication = getAuth(app)
        signInWithEmailAndPassword(authentication, cred.email, cred.password).then((response)=>{
            // navigate('/home')
            console.log(response);
            sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
          })
    }

    console.log(cred);

  return (
    <div className=' px-10 w-full min-h-screen  flex flex-col items-center justify-center gap-6'>
        <h1 className='uppercase'>REGISTER Form</h1>
        <input 
            type="email" 
            placeholder='Email' 
            className='px-4 py-2 min-w-[250px] w-[350px] outline-0 border-2 border-slate-300 focus:border-slate-600 rounded-md'
            onChange={(e)=>handleChange(e)}
            name='email'
        />

        <input 
            type="text" 
            placeholder='Password' 
            className='px-4 py-2 min-w-[250px] w-[350px] outline-0 border-2 border-slate-300 focus:border-slate-600 rounded-md'
            onChange={(e)=>handleChange(e)}
            name='password'
        />
        
        <button 
            className='px-4 py-2 bg-slate-600 hover:bg-slate-700 active:bg-slate-800 text-white rounded-md uppercase  font-semibold text-md' onClick={handleClick}>
        REGISTER</button>
    </div>
  )
}

export default Register