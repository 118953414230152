// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDR2u8RJr9W5zFOvSoEIxiY7AoNUF3cIH4",
  authDomain: "prayosha-arcitects.firebaseapp.com",
  projectId: "prayosha-arcitects",
  storageBucket: "prayosha-arcitects.appspot.com",
  messagingSenderId: "1063276251733",
  appId: "1:1063276251733:web:2f1f60644747ac4169e2dc",
  measurementId: "G-XZPPXGPGD8"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);