import React, { memo, useContext, useEffect, useState } from "react";
import { BsImage, BsUpload } from "react-icons/bs";
import { BiRightArrow } from "react-icons/bi";
import { deleteBufferFromImage, deleteFilesFromS3, saveFilesToS3 } from "../helper/helper";
import { deleteProject, getOneProject, saveProject } from "../api/api"; 
import { useNavigate, useParams } from "react-router-dom";
import {MdDelete} from 'react-icons/md'
import { MutateContext } from "../App";
import { deleteFile } from "../helper/S3";
import Navbar from "../components/Navbar";
import Loading2 from "../components/Loading2";
import { toast } from "react-toastify";

const ProjectPage = () => {
  

  const [updateGallery,setUpdateGallery] = useState({slides:[],sitephotos:[]})

  const [updateDetails,setUpdateDetails] = useState({projectlogo:false, coverImg:false, details:false})
  const [deleteS3images, setDeleteS3Images] = useState([])
  const {mutate,setMutate} = useContext(MutateContext)
 
  const {id} = useParams()
  const navigate = useNavigate()

  // upload photos to S3
  const [s3Upload, setS3Upload] = useState([]);

  // open and closing arrows for slides and sitephotos
  const [openSlides, setOpenSlides] = useState(false);
  const [openSitePhotos, setSitePhotos] = useState(false);
  const [loading,setLoading] = useState(true)
  const [project, setProject] = useState({
      projectlogo: '',
      coverImg: '',
      name: '',
      location: '',
      area: '',
      doc: '',
      type: '',
      description: '',
      slides: [],
      sitephotos: [],
  });

    useEffect(()=>{
      if(!sessionStorage.getItem('Auth Token')){
        navigate('/')
      }

      getOneProject(id).then((res)=>{console.log(res.data,'project'); setProject(res.data) ;setLoading(false)})
    },[])

    

    const handleChange = (e) => {
        setProject({ ...project, [e.target.name]: e.target.value });
        setUpdateDetails({...updateDetails, details:true})
    };

    const handleImage = async (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        const id = Math.floor(Math.random() * 100000 + 1);
        const fileName = `${id}.${file.name.split(".")[1]}`;

        let fileFormat = "image/jpeg";
        if (file.name.split(".")[1] === "png") {
          fileFormat = "image/png";
        } else if (file.name.split(".")[1] === "jpeg") {
          fileFormat = "image/jpeg";
        }

        const img = new Image();

        // load the image from the input file
        const reader = new FileReader();
        reader.onload = function (e) {
        img.src = e.target.result;
        };
        reader.readAsDataURL(file);

        // wait for the image to load
        img.onload = function () {
          // create a canvas element
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // set the canvas dimensions to match the image dimensions
          canvas.width = img.width;
          canvas.height = img.height;

          // draw the image onto the canvas
          ctx.drawImage(img, 0, 0);

        // compress the image and save it as a JPEG
          canvas.toBlob(
            function async(blob) {
              // create a new file object from the compressed data
              const compressedFile =   new File([blob], fileName, {type: fileFormat,});

              setS3Upload([...s3Upload, { name: fileName, body: compressedFile }]);
              if(!updateDetails[name]){
                console.log('delete karo') 
                
                setDeleteS3Images([...deleteS3images, project[name].name])
              }
              setProject((prev) => {
                return {
                  ...prev,
                  [name]: { name: fileName, body: compressedFile },
                };
              });
              setUpdateDetails({...updateDetails, [name]:true})
            },fileFormat,0.5
          );
        }; 
    };

    const handleGallery = async (e) => {
        const files = e.target.files;
        const arr = [];

        for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const id = Math.floor(Math.random() * 100000 + 1);
        const fileName = `${id}.${file.name.split(".")[1]}`;
        
        let fileFormat = "image/jpeg";
        if (file.name.split(".")[1] === "png") {
            fileFormat = "image/png";
        } else if (file.name.split(".")[1] === "jpeg") {
            fileFormat = "image/jpeg";
        }
        const img = new Image();

        // load the image from the input file
        const reader = new FileReader();
        reader.onload = function (e) {
            img.src = e.target.result;
        };
        reader.readAsDataURL(file);

        img.onload = function () {
            // create a canvas element
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // set the canvas dimensions to match the image dimensions
            canvas.width = img.width;
            canvas.height = img.height;

            // draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // compress the image and save it as a JPEG
            canvas.toBlob(
            function (blob) {
                // create a new file object from the compressed data
                const compressedFile = new File([blob], fileName, {
                type: fileFormat,
                });

                arr.push({ name: fileName, body: compressedFile });
                setS3Upload([
                ...s3Upload,
                { name: fileName, body: compressedFile },
                ]);
                setUpdateGallery({
                ...updateGallery,
                [e.target.name]: [...arr, ...updateGallery[e.target.name]],
                });
            },
            fileFormat,
            0.5
            );
        };
        }
        setProject({
        ...project,
        [e.target.name]: [...arr, ...project[e.target.name]],
        });
    };
    
    const deleteUpdateGallery = async(e,name) =>{
      console.log(e.target.name, name);
       setUpdateGallery({...updateGallery, [e.target.name]:[...updateGallery[e.target.name].filter((x)=>x.name!==name) ]})
    }

    const deleteProjectGallery = async(e,name)=>{
      console.log(e.target.name, name)
      setDeleteS3Images([...deleteS3images,name])
      setProject({...project, [e.target.name]:[...project[e.target.name].filter((x)=>x.name!==name) ]})
    }

    const notifySuccess = () => toast.success('🦄 Wow so easy!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

    const handleSubmit = async () => { 
      const project2 = {...project, slides:[...project.slides, ...updateGallery.slides], sitephotos:[...project.sitephotos, ...updateGallery.sitephotos]}
      let projectSubmit = deleteBufferFromImage(project2);
      
      console.log(s3Upload);
      console.log(project, 'project');
      console.log(project2, 'project2');
      console.log(projectSubmit, 'projectSubmit');
      const response = await saveFilesToS3(s3Upload);
      const deleteResponse = await deleteFilesFromS3(deleteS3images) 
      if (!response.some((x) => x.$metadata.httpStatusCode !== 200)) {
        // if(deleteResponse.length > 0 && )
        const request = await saveProject(projectSubmit).then((res) =>{ setMutate(2);notifySuccess(); return res});
        console.log(request);
      }
    };

    const handleDelete = async () => {
      if(deleteS3images.length > 0){
        const response = await deleteFilesFromS3(deleteS3images) 
      }
      console.log( 'step0')
      
      const arr = await [project.projectlogo, project.coverImg, ...project.slides, ...project.sitephotos].filter((x)=>!x.body).map((x)=>x.name) 
      console.log(arr, 'step1')
      const response1 = await deleteFilesFromS3(arr)
      console.log(  'step2')

      // console.log(project.id);
      const result = await deleteProject(project.id)
      console.log( result, 'step3')

      // console.log(result)
    }

    if(loading){
      return (
        <div className="flex flex-wrap bg-white w-full h-screen   p-auto gap-4 "><Loading2/></div>
      )
    }

  return (
    <>
    <Navbar/>
    <div className="flex flex-wrap bg-slate-100 w-full h-11/12 pt-20 pb-24 px-10 gap-4 ">
      
      {/* PROJECT DETAILS */}
      <div
        className="w-full bg-white rounded-lg flex lg:flex-nowrap flex-wrap
            [&>div>h1]:border-b
            [&>div>h1]:border-slate-500
            [&>div>h1]:p-2 
            [&>div]:rounded-md
            [&>div]:bg-white
            mt-2 
            [&_input]:border-slate-400 
            [&_input]:border-2 
            [&_input]:rounded-md   
            [&_input]:px-2 
            [&_input]:bg-white 
            [&_input]:py-1 
            [&_input]:outline-none 
            [&_input]:my-1 
        "
      >
        <div className="lg:w-1/4 w-full  m-2 px-2">
          <h1>Project Details</h1>

          {/* PROJECT LOGO */}
          <div className="relative w-36 h-36 mx-auto my-2">
            <div className="w-36 h-36 bg-slate-100 border-dashed border-2 border-slate-400 z-10 absolute pointer-events-none flex flex-col items-center justify-center text-center overflow-hidden">
              {!project.projectlogo ? (
                <>
                  <BsImage size={35} color={"#4b5563"} />
                  <p className="text-slate-600 text-bold">
                    Upload Project Logo
                  </p>
                </>
              ) : (
                <>
                {updateDetails.projectlogo ? <img
                  src={URL.createObjectURL(project.projectlogo.body)}
                  alt=""
                /> :  
                <img 
                src={`https://s3-mern-demo.s3.ap-south-1.amazonaws.com/${project.projectlogo.name}`}
                alt=""
              />}
              </>
              )}
            </div>
            <input
              type="file"
              name="projectlogo"
              id="projectlogo"
              onChange={handleImage}
              className="w-36 h-32 absolute inset-0 z-0 "
            />
          </div>

          <input
            type="text"
            name="name"
            placeholder="Project Name"
            value={project.name || ''}
            onChange={handleChange}
            className="focus:border-2 focus:border-blue-500 w-full"
          />
          <input
            type="text"
            name="location"
            placeholder="Project Location"
            onChange={handleChange}
            value={project.location || ''}
            className="focus:border-2 focus:border-blue-500 w-full"
          />
          <input
            type="number"
            name="area"
            placeholder="Sqft Area"
            value={project.area || ''}
            onChange={handleChange}
            className="focus:border-2 focus:border-blue-500 w-full"
          />
          <input
            type="date"
            name="doc"
            placeholder="date of Completion"
            onChange={handleChange}
            value={project.doc || ''}

            className="focus:border-2 focus:border-blue-500 w-full"
          />
          <select
            name="type"
            id=""
            className="px-2 py-1 outline-none border-2 border-slate-400 my-1 rounded-md w-full focus:border-blue-500"
            value={project.type || ''}
            onChange={handleChange}
          >
            <option value="" className="bg-slate-200 uppercase">
              Select Project type
            </option>
            <option value="residence" className="bg-slate-200 uppercase">
              Residence
            </option>
            <option value="housing" className="bg-slate-200 uppercase">
              housing
            </option>
            <option value="institute" className="bg-slate-200 uppercase">
              institute
            </option>
            <option value="commercial" className="bg-slate-200 uppercase">
              commercial
            </option>
            <option value="interior" className="bg-slate-200 uppercase">
              interior
            </option>
          </select>
          <textarea
            className="outline-none focus:border-blue-400 border-slate-400 border-2 w-full rounded-md p-2 mt-1"
            type="text"
            name="description"
            placeholder="Project Description"
            onChange={handleChange}
            value={project.description || ''}
          />
        </div>


        {/* COVER PHOTO */}
        <div className=" w-full m-2  px-2">
          <h1>Cover Photo</h1>
          <div className="relative w-11/12 h-[450px] mx-auto my-2">
            <div className="w-full h-[450px] bg-slate-100 border-dashed border-2 border-slate-400 z-10 absolute pointer-events-none flex flex-col items-center justify-center text-center overflow-hidden ">
              {!project.coverImg ? (
                <>
                  <BsImage size={35} color={"#4b5563"} />
                  <p className="text-slate-600 text-bold">
                    Upload Project Logo
                  </p>
                </>
              ) : (
                <>
                  {updateDetails.coverImg ? <img src={URL.createObjectURL(project.coverImg.body)} alt=""/> 
                  : <img src={`https://s3-mern-demo.s3.ap-south-1.amazonaws.com/${project.coverImg.name}`} alt=""/>
                  }
                  
                </>
              )}
            </div>
            <input
              type="file"
              name="coverImg"
              id="coverImg"
              onChange={handleImage}
              className="w-full h-[400px] absolute inset-0 z-0  "
            />
          </div>
        </div>
      </div>
      
      {/* SLIDES */}
      <div className="rounded-lg w-full h-fit bg-white px-4">
        <h1 className="  p-2  flex  items-center gap-2">
          Slides{" "}
          <button
            onClick={() => setOpenSlides(!openSlides)}
            className={openSlides ? "rotate-90" : "rotate-0"}
          >
            <BiRightArrow />
          </button>
        </h1>
        <div
          className={`w-full pb-4 flex  flex-wrap gap-2 ${
            openSlides ? "" : "hidden"
          }`}
        >
          {updateGallery.slides?.map((x, i)=>(
            <div
            className="w-56 h-28 bg-slate-200 flex items-center justify-center relative gallery-card"
            key={x.name}
          >
            <img
              src={URL.createObjectURL(x.body)}
              alt=""
              className="w-full h-full object-scale-down	"
            />
            <button className="absolute bottom-0 right-0 m-1 bg-red-600 rounded-full p-1 delete-button" onClick={(e)=>deleteUpdateGallery(e,x.name)} name='slides'><MdDelete size={20} color={'white'} className='pointer-events-none' /></button>
          </div>
          ))}
          {project.slides?.map((x, i) => (
            <div
              className="w-56 h-28 bg-slate-200 flex items-center justify-center relative gallery-card"
              key={x.name}
            >
              <img
                src={`https://s3-mern-demo.s3.ap-south-1.amazonaws.com/${x.name}`}
                alt=""
                className="w-full h-full object-scale-down	"
              />
              <button className="absolute  bottom-0 right-0 m-1 bg-red-600 rounded-full p-1 delete-button" onClick={(e)=>deleteProjectGallery(e,x.name)} name='slides' ><MdDelete size={20} color={'white'} className='pointer-events-none'/></button>

            </div>
          ))}
          <div className="w-56 h-28 bg-slate-200 rounded-md flex items-center justify-center relative">
            <BsUpload size={35} color={"#4b5563"} />
            <input
              type="file"
              className="w-full border border-slate-400 h-full absolute opacity-0"
              onChange={handleGallery}
              multiple
              name="slides"
            />
          </div>
        </div>
      </div>

      {/* SITE PHOTOS */}
      <div className="rounded-lg w-full h-fit bg-white px-4">
        <h1 className="  p-2   flex items-center gap-2">
          Site Photos{" "}
          <button
            onClick={() => setSitePhotos(!openSitePhotos)}
            className={openSitePhotos ? "rotate-90" : "rotate-0"}
          >
            <BiRightArrow />
          </button>
        </h1>
        <div
          className={`w-full pb-4 flex flex-wrap gap-2 ${
            openSitePhotos ? "" : "hidden"
          }`}
        >
          {updateGallery.sitephotos?.map((x, i)=>(
            <div
            className="w-56 h-28 bg-slate-200 flex items-center justify-center relative gallery-card"
            key={i}
          >
            <img
              src={URL.createObjectURL(x.body)}
              alt=""
              className="w-full h-full object-scale-down	"
            />
             <button className="absolute  bottom-0 right-0 m-1 bg-red-600 rounded-full p-1 delete-button" onClick={(e)=>deleteUpdateGallery(e,x.name)} name='sitephotos' ><MdDelete size={20} color={'white'} className='pointer-events-none'/></button>
          </div>
          ))}
          {project.sitephotos?.map((x, i) => (
            <div
              className="w-56 h-28 bg-slate-200 flex items-center justify-center relative gallery-card"
              key={i}
            >
              <img
                src={`https://s3-mern-demo.s3.ap-south-1.amazonaws.com/${x.name}`}
                alt=""
                className="w-full h-full object-scale-down	"
              />
               <button className="absolute  bottom-0 right-0 m-1 bg-red-600 rounded-full p-1 delete-button" onClick={(e)=>deleteProjectGallery(e,x.name)} name='sitephotos' ><MdDelete size={20} color={'white'} className='pointer-events-none'/></button>
            </div>
          ))}
          <div className="w-56 h-28 bg-slate-200 rounded-md flex items-center justify-center relative">
            <BsUpload size={35} color={"#4b5563"} />
            <input
              type="file"
              className="w-full border border-slate-400 h-full absolute opacity-0"
              onChange={handleGallery}
              multiple
              name="sitephotos"
            />
          </div>
        </div>
      </div>

      <div className="  w-full flex justify-center gap-2">
        <button
          className="px-2 py-2 border border-blue-600 text-blue-600 rounded-md  hover:bg-blue-600 hover:text-white font-semibold"
          onClick={handleSubmit}
        >
          UPDATE PROJECT
        </button>
        {/* <button className="px-2 py-2 border border-red-600 text-red-600 rounded-md   hover:bg-red-600 hover:text-white font-semibold"
          onClick={handleDelete}>
          DELETE PROJECT
        </button> */}
      </div>
      
    </div>
    </>
  );
};

export default memo(ProjectPage);
