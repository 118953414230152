import axios from "axios";

const axiosInstance = axios.create({
    baseURL:'https://wpjwpqs74c.execute-api.ap-south-1.amazonaws.com/prod'
})

export const saveProject = async(data) =>{
    const response = await axiosInstance.post('/projects',data)
    return response.data
}

export const getAllProjects = async(data) =>{
    const response = await axiosInstance.get('/projects')
    return response
}

export const getOneProject = async(id) =>{
    const response = await axiosInstance.get(`/projects/${id}`)
    return response
}

export const deleteProject = async(id)=>{
    console.log(id, 'id');
    // const response = await axiosInstance.delete(`/projects/${id}`)
    const response = await axios.delete(`https://wpjwpqs74c.execute-api.ap-south-1.amazonaws.com/prod/projects/${id}`)
    console.log(id,'step5');

    return response
}

export const getProjectType = async(type) =>{
    const response = await axiosInstance.put('https://wpjwpqs74c.execute-api.ap-south-1.amazonaws.com/prod/projects',{type:type})
    return response.data
}